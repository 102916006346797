import React from 'react';
import CmsRenderer from '@core_modules/cms/components/cms-renderer';
import { COLORS } from '@core/theme/vars';

const FooterV1 = (props) => {
    const {
        data, loading, storeConfig,
    } = props;
    return (
        <>
            <div className="cms-container wrapper-footer bg-[#232323] !text-neutral-white">
                {!loading ? <CmsRenderer content={data.cmsBlocks.items[0].content} storeConfig={storeConfig} /> : null}
                <style jsx global>
                    {`
                        #footer-bottom {
                            padding: 30px 15px;
                        }
                        @media screen and (max-width: 768px) {
                            #footer-bottom {
                                padding: 30px 0px;
                            }

                            #accordion-footer .mgz-element-inner {
                                padding: 0px !important;
                            }

                            #footer-contact {
                                padding: 0px 10px;
                            }

                            #footer-contact .store-addr:nth-of-type(1) {
                                text-align: center;
                            }

                            #logo-footer {
                                display: flex;
                                justify-content: center;
                            }

                            .find-us-label, .sosmed-icon {
                                display: flex;
                                justify-content: center
                            }
                          
                        }
                        .footer-links a {
                            display: block;
                            font-size: 13px !important;
                            color: #FFF !important;
                            &:hover {
                                color: ${COLORS.primary.DEFAULT};
                            }
                            line-height: 14px !important;
                        }
                        .footer-links br {
                            content: '';
                            display: block;
                            margin-top: 10px;
                            line-height: 22px;
                        }

                        .footer-subtitle {
                            font-family: "Plus Jakarta Sans Bold", sans-serif;
                        }
                        
                        .footer-subtitle div div div p strong {
                            color: #FFF !important;
                        }

                        .footer-subtitle > .mgz-element-inner {
                            padding-bottom: 0px !important;
                        }

                        .store-addr {
                            color: #FFF !important;
                        }

                        .sosmed-icon .magezon-icon-inner {
                            border-radius: 100% !important;
                        }

                        #pwa-footer-mp > .pwa-footer-mp-inner {
                            padding-top: 5px;
                        }
                        #pwa-footer-mp > .pwa-footer-mp-inner > .w-full .mgz-column .mgz-element-inner {
                            padding: 5px 7px;
                        }


                        .mgz-accordion :global(.accordion-title){
                            text-align: center !important;
                        }

                        .mgz-accordion :global(.magezon-icon) {
                            position: absolute;
                            right: 15px;
                        }

                        .mgz-accordion .accordion-summary {
                            justify-content: center !important;
                        }

                        .mgz-accordion .accordion-content {
                            color: #fff !important;
                        }

                        .mgz-accordion .accordion-content a {
                            color: #fff !important;
                            line-height: 20px;
                            margin-bottom: 5px;
                        }
                    `}
                </style>
            </div>
        </>
    );
};

FooterV1.propTypes = {};

export default FooterV1;
